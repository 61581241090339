var swiper = new Swiper(".swiper", {
  slidesPerView: 1,
  loop: true,

    spaceBetween: 10,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

  breakpoints: {
    // when window width is >= 320px

    // when window width is >= 480px
    991: {
      slidesPerView: 2,
      spaceBetween: 10
    },
    // when window width is >= 640px
    1300: {
      slidesPerView: 3,
      spaceBetween: 10
    }
  }
});

const swiper2 = new Swiper('.swiper2', {
  // Optional parameters
  slidesPerView: 1,
  loop: true,
  autoplay: {
    delay: 2000,
    disableOnInteraction: false,
  },

  // If we need pagination


  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next2',
    prevEl: '.swiper-button-prev2',
  },
  pagination: {
    el: '.swiper-pagination',
  },

  spaceBetween: 10,
  //   scrollbar: {
  //   el: ".swiper-scrollbar",

  // },
  breakpoints: {
    576: {
      slidesPerView: 2,
      spaceBetween: 10
    },

    // when window width is >= 480px
    991: {
      slidesPerView: 3,
      spaceBetween: 10
    },
    1250: {
      slidesPerView: 4,
      spaceBetween: 10
    },
    // when window width is >= 640px
    1380: {
      slidesPerView: 5,
      spaceBetween: 10
    }
  }

  // And if we need scrollbar

});
